@import '../../global/style-guide';

$cartImageWidth: 100px; // cart item image width used in the <img> tag
.cartWidgetContainer {
	background: url(/images/global/ajax-loader.gif?v=1) no-repeat center;
	height:188px;
	max-width: 410px;
    min-width: 323px;
	@media only screen 
		and (min-device-width: 768px) 
		and (max-device-width: 1024px) {
			min-width: 270px;
		}
	width: 100%;
	&__header { font-size: 18px; font-weight: 500; }
	&__headerQty { font-size: 18px; font-weight: normal; }

	&__list {
		height: 148px;
		padding: 0;

		.collapsibleItem {
			height: 138px;
			width: 115px;
			img { width: auto; }
			a { height: auto; padding-bottom: 0; }
		}
	}
	&__prodLink { text-decoration: none; }
	&__prodName {
		display: block;
		font: 13px/1.2em var(--fontHeading);
    	margin: 6px auto 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: $cartImageWidth;
	}
	&__prodPrice {
		display: block;
		font: 500 14px/1.85em var(--fontHeading);
		margin: 10px auto 0 auto;
		width: $cartImageWidth;
	}
	&__rightPane {
		font-family: var(--fontHeading);
		line-height: 1.85em;
		padding-top:10px;
		width: 180px;
		@media only screen 
		and (min-device-width: 768px) 
		and (max-device-width: 1024px) {
			width: 145px;
		}
	}
	&__orderTotal { font-size: 18px; font-weight: 500; padding-bottom: 10px; }
	&__itemCount { font-size: 14px; margin-bottom: 20px; white-space: nowrap; }
	&__checkOutNow {
		.cartWidgetContainer__rightPane &  { font-size: 17px; } // override global style
		font-weight: 500;
		height: 53px;
		line-height: 19px;
		white-space: nowrap;
		width: 100%;
	}
}